import {
  Collection,
  Money
} from "@/utils/api-client";
import { createRepaymentProvider } from "@/utils/di";
import { PaymentSchedule } from "../models/PaymentSchedule";
import { PaymentScheduleRepayment } from "../models/PaymentScheduleRepayment";

export class RepaymentProvider {
  async getList(page = 1, perPage = 25): Promise<Collection<PaymentSchedule>> {
    const provider = await createRepaymentProvider();
    const collection = await provider.getList(page, perPage);
    return new Collection<PaymentSchedule>(collection.items.map((group) => {
      let totalPrincipalAmount = group.repayments.reduce((prev, item) => prev + item.principal.amount, 0);
      const repayments = group.repayments.map((item) => {
        totalPrincipalAmount -= item.principal.amount;
        return new PaymentScheduleRepayment(item, new Money(totalPrincipalAmount, item.principal.currencyCode));
      })
      return new PaymentSchedule(
        group.project,
        repayments,
        repayments.find((item) => group.nextRepayment && item.uuid === group.nextRepayment.uuid ? item : undefined)
      );
    }), collection.count, collection.totalCount);
  }
}
