<template>
  <div class="repayment-schedules">
    <div class="container-xl">
      <div class="card block-title">
        <div class="card-header">
          <div class="card-title">График выплат</div>
        </div>
      </div>
      <paginated-list :count="collection.count"
                      :total-count="collection.totalCount"
                      :data-loading="collection.loading"
                      @update="loadData"
      >
        <template v-slot:loading>
          <div class="card block-repayments">
            <div class="card-body">
              <i>Загрузка...</i>
            </div>
          </div>
        </template>
        <template v-slot:no-items>
          <div class="card block-repayments">
            <div class="card-body">
              <i>Нет данных</i>
            </div>
          </div>
        </template>
        <div v-for="(item, index) in collection.items" class="card block-repayments">
          <div class="card-header">
            <div v-if="item.nextRepayment"
                 :class="{ 'text-danger': !item.nextRepayment.paid && item.nextRepayment.overdue }" class="row">
              <div class="col-project-name">
                <div class="small text-muted">Проект</div>
                {{ item.project.name }}
                <button @click="toggle[index] = !toggle[index]" class="btn btn-sm btn-outline-success" type="button">
                  <i v-if="toggle[index]" class="bi-chevron-up"></i>
                  <i v-if="!toggle[index]" class="bi-chevron-down"></i>
                </button>
              </div>
              <div class="col-payment-date">
                <div class="small text-muted">Дата по графику</div>
                <date-format :date="item.nextRepayment.date"/>
              </div>
              <div class="col-interest">
                <div class="small text-muted">%</div>
                <money :money="calculateInterest(item.nextRepayment)" :decimal="true"/>
              </div>
              <div class="col-principal">
                <div class="small text-muted">Основной долг</div>
                <money :money="item.nextRepayment.principal" :decimal="true"/>
              </div>
              <div class="col-paid-at">
                <div class="small text-muted">Дата оплаты</div>
                <span v-if="item.nextRepayment.paidAt">
                  <date-format :date="item.nextRepayment.paidAt"/>
                </span>
              </div>
              <div class="col-total">
                <div class="small text-muted">Сумма платежа</div>
                <money :money="calculateTotal(item.nextRepayment)" :decimal="true"/>
              </div>
              <div class="col-principal-remain">
                <div class="small text-muted">Остаток ОД</div>
                <money :money="item.nextRepayment.principalRemain" :decimal="true"/>
              </div>
            </div>
            <div v-if="!item.nextRepayment" class="row">
              <div class="col-project-name">{{ item.project.name }}</div>
              <div class="col-payment-date">-</div>
              <div class="col-interest">-</div>
              <div class="col-principal">-</div>
              <div class="col-total">-</div>
              <div class="col-principal-remain">-</div>
              <div class="col-paid-at">-</div>
            </div>
          </div>
          <div v-if="toggle[index]" class="card-body">
            <!--            <div v-for="repayment in item.repayments" :class="{ 'text-danger': !repayment.paid && repayment.overdue }"-->
            <!--                 class="row">-->
            <div v-for="repayment in item.repayments" :class="!repayment.paid && repayment.overdue ? 'text-danger' :
                     (!repayment.overdue && repayment.paid ? 'text-success' :
                     (repayment.overdue ? 'text-warning' : ''))"
                 class="row">

              <div class="col-paid-status">
                <i v-if="repayment.paid" class="bi-check-square text-success"></i>
                <i v-if="!repayment.paid" class="bi-square text-muted"></i>
              </div>
              <div class="col-payment-date">
                <div class="small text-muted">Дата</div>
                <date-format :date="repayment.date"/>
              </div>
              <div class="col-interest">
                <div class="small text-muted">%</div>
                <money :money="calculateInterest(repayment)" :decimal="true"/>
              </div>
              <div class="col-principal">
                <div class="small text-muted">Основной долг</div>
                <money :money="repayment.principal" :decimal="true"/>
              </div>
              <div class="col-paid-at">
                <div class="small text-muted">Дата оплаты</div>
                <span v-if="repayment.paidAt">
                  <date-format :date="repayment.paidAt"/>
                </span>
              </div>
              <div class="col-total">
                <div class="small text-muted">Сумма платежа</div>
                <money :money="calculateTotal(repayment)" :decimal="true"/>
              </div>
              <div class="col-principal-remain">
                <div class="small text-muted">Остаток ОД</div>
                <money :money="repayment.principalRemain" :decimal="true"/>
              </div>
            </div>
          </div>
        </div>
      </paginated-list>
    </div>
  </div>
</template>

<script>
import {DataCollection, DateFormat, Money, PaginatedList} from "@/components/common";
import {Collection, Money as ApiMoney} from "@/utils/api-client";
import {RepaymentProvider} from "./providers/RepaymentProvider";

export default {
  name: "RepaymentSchedules",
  components: {
    DateFormat,
    Money,
    PaginatedList
  },
  data() {
    return {
      collection: new DataCollection(),
      toggle: []
    };
  },
  methods: {
    async loadData(page, perPage) {
      this.collection.startLoading();
      try {
        const repaymentProvider = new RepaymentProvider();
        const collection = await repaymentProvider.getList(page, perPage);

        const repaymentsByProject = this.sumByProject(collection)
        const coll = new Collection(repaymentsByProject, repaymentsByProject.length, repaymentsByProject.length)
        this.collection.setCollection(coll);
      } finally {
        this.collection.finishLoading();
        console.log(this.collection);
      }
    },
    sumByProject(collection) {
      let repaymentsByProject = [];
      let repaymentByProjectArrIndex = []
      collection.items.forEach((schedule) => {

        if (!repaymentsByProject[schedule.project.uuid]) {
          repaymentsByProject[schedule.project.uuid] = schedule;
          repaymentByProjectArrIndex.push(schedule)
        } else {
          schedule.repayments.forEach((repayment, index) => {
            let index_correct_by_date = index
            while (repayment.date !== repaymentsByProject[schedule.project.uuid].repayments[index_correct_by_date].repayment.date) {
              if (!repaymentsByProject[schedule.project.uuid].repayments[index_correct_by_date + 1]) break;
              index_correct_by_date += 1;

            }
            if (repaymentsByProject[schedule.project.uuid].repayments[index_correct_by_date]) {
              const repaymentInterestSum = repaymentsByProject[schedule.project.uuid].repayments[index_correct_by_date].repayment.interest;
              repaymentsByProject[schedule.project.uuid].repayments[index_correct_by_date].repayment.interest = new ApiMoney(repayment.interest.amount + repaymentInterestSum.amount, 'RUB');

              const repaymentPrincipalSum = repaymentsByProject[schedule.project.uuid].repayments[index_correct_by_date].repayment.principal;
              repaymentsByProject[schedule.project.uuid].repayments[index_correct_by_date].repayment.principal = new ApiMoney(repaymentPrincipalSum.amount + repayment.principal.amount, 'RUB');

              const repaymentPrincipalRemainSum = repaymentsByProject[schedule.project.uuid].repayments[index_correct_by_date].principalRemain;
              repaymentsByProject[schedule.project.uuid].repayments[index_correct_by_date].principalRemain = new ApiMoney(repaymentPrincipalRemainSum.amount + repayment.principalRemain.amount, 'RUB');
            }
          });
        }
      });
      return repaymentByProjectArrIndex;
    },

    calculateInterest(repayment) {
      let total = repayment.interest.amount;
      if (repayment.penalty) {
        total += repayment.penalty.amount;
      }
      return new ApiMoney(total, repayment.interest.currencyCode);
    },
    calculateTotal(repayment) {
      let total = repayment.principal.amount + repayment.interest.amount;
      if (repayment.penalty) {
        total += repayment.penalty.amount;
      }
      return new ApiMoney(total, repayment.principal.currencyCode);
    },
  }
};
</script>

<style lang="scss">
.repayment-schedules {
  .block-title {
    margin-bottom: 0;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .block-repayments {
    margin-top: 0;
  }

  .block-repayments:not(:last-child) {
    margin-bottom: 0;
    border-radius: 0;
  }

  .block-repayments:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  .block-repayments {
    .card-header .row {
      position: relative;
    }

    .col-project-name,
    .col-paid-status,
    .col-payment-date,
    .col-paid-at,
    .col-interest,
    .col-principal,
    .col-total,
    .col-principal-remain {
      padding: 1%;
    }

    .col-project-name {
      width: 100%;
      font-weight: 500;

      .small.text-muted {
        display: none;
      }

      button {
        position: absolute;
        bottom: 0.25rem;
        left: 1%;
        height: 1.5rem;
        width: 1.5rem;
        padding: 0.125rem;
        line-height: 0.75rem;
        border-radius: 0.75rem;
      }
    }

    .col-paid-status {
      width: 10%;

      i::before {
        font-size: 1.25rem;
        margin-left: 3%;
      }
    }

    .col-payment-date {
      width: 24%;
    }

    .col-paid-at {
      width: 24%;
    }

    .col-interest,
    .col-principal,
    .col-total,
    .col-principal-remain {
      width: 33%;
    }

    .col-paid-at {
      margin-left: 10%;
    }

    .card-header {
      .col-payment-date {
        margin-left: 10%;
      }
    }

    .card-body {
      padding-top: 0;

      .row {
        margin-top: 0.5rem;
      }
    }

    @media (min-width: 768px) { // Bootstrap MD
      .small.text-muted {
        margin-bottom: 0.5rem;
      }

      .col-project-name {
        width: 20%;

        .small.text-muted {
          display: block;
        }

        button {
          right: 1%;
          bottom: 0.875rem;
          left: auto;
        }
      }
      .col-paid-status {
        width: 20%;
      }

      .col-payment-date {
        width: 12%;
      }

      .col-paid-at {
        width: 12%;
      }

      .col-interest,
      .col-principal,
      .col-total,
      .col-principal-remain {
        width: 14%;
      }

      .col-paid-at {
        margin-left: 0;
      }

      .card-header {
        .col-payment-date {
          margin-left: 0;
        }
      }

      .card-body {
        .row {
          margin-top: 0;
        }

        .small.text-muted {
          display: none;
        }
      }
    }
  }
}
</style>
