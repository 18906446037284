import { ShortProject } from "@/utils/api-client/models/project/ShortProject";
import { PaymentScheduleRepayment } from "./PaymentScheduleRepayment";

export class PaymentSchedule {
  constructor(
    readonly project: ShortProject,
    readonly repayments: Array<PaymentScheduleRepayment>,
    readonly nextRepayment?: PaymentScheduleRepayment
  ) {}
}
