import { Money } from "@/utils/api-client";
import { Repayment } from "@/utils/api-client/models/repayment/Repayment";

export class PaymentScheduleRepayment {
  constructor(private readonly repayment: Repayment, readonly principalRemain: Money) {}

  get uuid(): string {
    return this.repayment.uuid;
  }

  get date(): string {
    return this.repayment.date;
  }

  get paidAt(): string {
    return this.repayment.paidAt;
  }

  get month(): number {
    return this.repayment.month;
  }

  get overdue(): boolean {
    return this.repayment.overdue;
  }

  get paid(): boolean {
    return this.repayment.paid;
  }

  get principal(): Money {
    return this.repayment.principal;
  }

  get interest(): Money {
    return this.repayment.interest;
  }

  get penalty(): Money | undefined {
    return this.repayment.penalty;
  }
}
